/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Graphik";
  font-weight: normal;
  src: url("./assets/fonts/GraphikRegular.otf");
}

@font-face {
  font-family: "GraphikExtralight";
  font-weight: normal;
  src: url("./assets/fonts/GraphikExtralight.otf");
}

@font-face {
  font-family: "GraphikLight";
  font-weight: normal;
  src: url("./assets/fonts/GraphikLight.otf");
}

@font-face {
  font-family: "GraphikSemibold";
  font-weight: normal;
  src: url("./assets/fonts/GraphikMedium.otf");
}

@font-face {
  font-family: "GraphikBold";
  font-weight: normal;
  src: url("./assets/fonts/Graphik-Bold-Trial.otf");
}

@font-face {
  font-family: "GraphikBoldWide";
  font-weight: normal;
  src: url("./assets/fonts/GraphikWide-Bold-Trial.otf");
}

/* custom css */

.bg-textGradient-custom {
  background: linear-gradient(
    283deg,
    #fff 15.27%,
    #fff 27.45%,
    rgba(255, 255, 255, 0.6) 56.91%,
    #fff 78.16%,
    rgba(255, 255, 255, 0.78) 101.64%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-textGradient-customVariant {
  background: radial-gradient(
    30.15% 174.19% at 50% 56.45%,
    rgba(255, 255, 255, 0.92) 0%,
    rgba(255, 255, 255, 0.24) 100%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.bg-blueGradient {
  background: radial-gradient(
    circle at 50% 150%,
    #0b0b1b 0%,
    #010314 70%,
    #2b2e73 88.62%,
    #6366f1 95.61%,
    #9c9efc 96.98%
  );
}

.bg-darkerBlueGradient {
  background: linear-gradient(
    180deg,
    #9c9efc 0.15%,
    #5c5fe1 12.23%,
    #060821 51.34%,
    #000 100.11%,
    #010315 100.12%
  );
}

.bg-darkerBlueGradientVariant {
  background: radial-gradient(
    108.84% 144.61% at 45.325% -29.5%,
    #010314 0%,
    #2b2e73 76.97%,
    #6366f1 83.78%,
    #9c9efc 89.78%
  );
}


.blueBlackGradient {
  background: radial-gradient(
    163.31% 137.44% at 49.96% -25.72%,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.8) 36.59%,
    rgba(12, 14, 45, 0.8) 58.28%,
    rgba(67, 70, 149, 0.8) 75.79%,
    rgba(99, 102, 241, 0.8) 86.61%,
    rgba(156, 158, 252, 0.8) 96.98%
  );
}

.navHeightCustom {
  height: calc(100vh - 0px);
  width: 100%;
}

.shadow-custom {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* .shadow-custom-lg {
  box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.1);
} */

.bg-linearBlueWhite {
  background: linear-gradient(90deg, #05192d 0%, #dbd1c3 75.24%, #feefdb 100%);
}


@media (min-width: 768px) {
  .shadow-custom-lg {
    box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.1);
  }
}

@media (min-width: 640px) {
  .shadow-customVariant {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}
